enum EnvironmentTypes {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

type EnvironmentFlags = {
  [key in EnvironmentTypes]: boolean;
};

const createEnvironmentFlags = (
  development = false,
  staging = false,
  production = false
): EnvironmentFlags => ({
  development,
  staging,
  production,
});

const flags = {
  pdpDebug: createEnvironmentFlags(true, true, false),
  cashDisbursementDiscountTest: createEnvironmentFlags(true, true, true), // REGISTRY-4041
  packListingPage: createEnvironmentFlags(true, true, true), // REGISTRY-4176
  passTransactionId: createEnvironmentFlags(true, true, true), // REGISTRY-4111
  enableProductClickedTracker: createEnvironmentFlags(true, true, true), // REGISTRY-4137
  productReview: createEnvironmentFlags(true, false, false), // REGISTRY-4109
  personalization: createEnvironmentFlags(true, true, true), // REGISTRY-3377
  enableMostWantedTest: createEnvironmentFlags(true, true, true), // REGISTRY-4238
  enablePickToolTest: createEnvironmentFlags(true, true, true), // REGISTRY-4328
  addCOVIDPDP: createEnvironmentFlags(true, true, false), // REGISTRY-4249
  showMarketingTiles: createEnvironmentFlags(true, true, true), // REGISTRY-4225
  enableSelfPurchaseSection: createEnvironmentFlags(true, true, true), // REGISTRY-4378
  quickAddPackSkeleton: createEnvironmentFlags(true, true, true), // REGISTRY-4345
  productListViewedShopPLP: createEnvironmentFlags(true, true, true),
  componentShopping: createEnvironmentFlags(true, true, false),
  showEmailCapture: createEnvironmentFlags(true, true, true), // REGISTRY-4742
  showNoResultPage: createEnvironmentFlags(true, true, true), // REGISTRY-4852
  datadog: createEnvironmentFlags(false, true, true), // Toggle datadog monitoring
  holidayShipping: createEnvironmentFlags(true, true, true),
};

const getEnvironment = (): EnvironmentTypes => {
  const zolaEnv = window.zola && window.zola.env;
  switch (zolaEnv) {
    case EnvironmentTypes.DEVELOPMENT:
    case 'dev':
      return EnvironmentTypes.DEVELOPMENT;
    case 'staging':
    case 'stage':
      return EnvironmentTypes.STAGING;
    case 'production':
    case 'prod':
      return EnvironmentTypes.PRODUCTION;
    default:
      return EnvironmentTypes.PRODUCTION;
  }
};

const get = (flag: keyof typeof flags): boolean => {
  if (flags[flag]) {
    return Boolean(flags[flag][getEnvironment()]);
  }
  return false;
};

export default {
  get,
  getEnvironment,
};
